// Composables
import useRequest from "@/composables/useRequest";
import { useStore } from "@/store";

/**
 * @returns {Object}
 */
export default function useIntegration() {
  // Composables
  const { request } = useRequest();
  const store = useStore();

  // Methods
  const getEnabledIntegrations = async () => {
    return await request({
      endpoint: "platform.integration.enabled"
    });
  };

  const getIntegrations = async () => {
    return await request({
      endpoint: "platform.integration"
    });
  };

  const addIntegration = async data => {
    data = {
      ...data,
      end_user_email_address: store.getters["user/email"]
    };

    return await request({
      endpoint: "platform.integration.create",
      pathParams: {},
      data
    });
  };

  const updateIntegration = async (data, id) => {
    return await request({
      endpoint: "platform.integration.update",
      pathParams: {
        id
      },
      data
    });
  };

  const deleteIntegration = async id => {
    await request({
      endpoint: "platform.integration.delete",
      pathParams: {
        id
      }
    });
  };

  const synchronizeIntegrationData = async data => {
    data = {
      ...data,
      updated_by: store.getters["user/email"]
    };

    return await request({
      endpoint: "platform.integration.synchronize",
      pathParams: {},
      data
    });
  };

  return {
    getEnabledIntegrations,
    getIntegrations,
    addIntegration,
    updateIntegration,
    deleteIntegration,
    synchronizeIntegrationData
  };
}
