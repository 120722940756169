<template>
  <h2 :class="{ 'intro-y': hasAnimation }">
    <slot>
      {{ title }}
    </slot>
  </h2>
</template>

<script>
// Composables
import useAnimation from "@/composables/useAnimation";

export default {
  props: {
    title: {
      type: String,
      default: ""
    }
  },
  setup() {
    // Composables
    const { hasAnimation } = useAnimation();

    return {
      hasAnimation
    };
  }
};
</script>
