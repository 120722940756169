import { inject } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";
// Constants
import dateFormats from "@/constants/dateFormats";

export default function useDisplay() {
  // Misc
  const store = useStore();
  const { t } = useI18n();
  const moment = inject("moment");

  // Methods
  const trimHTML = str => {
    const el = document.createElement("div");
    el.innerHTML = str;
    return el.textContent.trim();
  };

  const and = (str, delimiter = " ") => {
    return str.split(delimiter).reduce((acc, cv, index, arr) => {
      if (index === 0) {
        return cv;
      } else if (index === arr.length - 1) {
        return `${acc} ${t("app.and")} ${cv}`;
      } else {
        return `${acc}, ${cv}`;
      }
    }, "");
  };

  const lowercase = str => str?.toLowerCase() ?? "";

  const formatAmount = (amount, decimals = 2) => {
    const value =
      typeof amount === "string" ? Number.parseFloat(amount) : amount;
    // Taken from https://stackoverflow.com/questions/149055/how-to-format-numbers-as-currency-strings
    return value
      .toFixed(decimals)
      .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
  };

  /**
   * Escapes dangerous character from a string
   * Taken from https://stackoverflow.com/questions/7381974/which-characters-need-to-be-escaped-in-html
   *
   * @param {String} dangerousInput
   * @returns {String}
   */
  const escapeHTML = dangerousInput => {
    const dangerousString = String(dangerousInput);
    const matchHtmlRegExp = /["'&<>]/;
    const match = matchHtmlRegExp.exec(dangerousString);

    if (!match) {
      return dangerousInput;
    }

    const encodedSymbolMap = {
      '"': "&quot;",
      "'": "&#39;",
      "&": "&amp;",
      "<": "&lt;",
      ">": "&gt;"
    };

    return dangerousString
      .split("")
      .map(character => encodedSymbolMap[character] || character)
      .join("");
  };

  const formatSeconds = s => {
    const unit = "seconds";
    const hours = moment.duration(s, unit).hours();
    const minutes = moment.duration(s, unit).minutes();
    const seconds = moment.duration(s, unit).seconds();

    let time = "";

    if (hours) {
      time = `${hours}h`;
    }

    if (minutes) {
      time = `${time}${minutes}m`;
    }

    if (seconds) {
      time = `${time}${seconds}s`;
    }

    return time;
  };

  const formatDate = args => {
    const {
      date,
      displayTime = false,
      fallback = "-",
      format = dateFormats.HUMAN_DATE_FORMAT
    } = args;

    const dateFormat = displayTime
      ? `${format} ${dateFormats.HUMAN_TIME_FORMAT}`
      : format;

    return date ? moment(date).format(dateFormat) : fallback;
  };

  const sortBy = (data, by) => {
    return (
      data?.slice()?.sort((a, b) => {
        return a[by] - b[by];
      }) ?? []
    );
  };

  const sortByDisplayOrder = data => {
    return sortBy(data, "display_order");
  };

  const getAvatarURL = avatar => {
    if (!avatar) {
      return;
    }

    const baseURL = store.getters["api/baseURL"];
    const api_token = store.getters["user/api_token"];
    const url = `${baseURL}${avatar}?api_token=${api_token}`;

    return url;
  };

  return {
    escapeHTML,
    getAvatarURL,
    lowercase,
    sortBy,
    sortByDisplayOrder,
    trimHTML,
    and,
    formatAmount,
    formatDate,
    formatSeconds
  };
}
