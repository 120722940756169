<template>
  <div>
    <div
      v-for="(error, index) in errors"
      :key="index"
      class="text-theme-6 mt-2"
    >
      {{ error.$message }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    errors: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
