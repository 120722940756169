<template>
  <div class="z-50">
    <div class="flex justify-between my-10">
      <div class="flex">
        <div class="input-group w-96 mr-3">
          <input
            :value="search"
            type="text"
            data-test="simple-search"
            class="form-control"
            :placeholder="$t(`app.search`)"
            :aria-label="$t(`app.search`)"
            aria-describedby="input-group-search"
            @input="onInput"
          />
          <div class="input-group-text chk-search">
            <i class="fal fa-search" />
          </div>
        </div>

        <VButton
          v-if="!hideAdvancedSearch"
          icon="fa-filter"
          data-test="advanced-search"
          class="chk-filters"
          :class="{ 'chk-filters-active': showAdvancedSearch }"
          @click="$emit('update:showAdvancedSearch', !showAdvancedSearch)"
        />
      </div>

      <slot />
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "@/store";
import VButton from "@/components/VButton";

export default {
  components: {
    VButton
  },
  props: {
    showAdvancedSearch: {
      type: Boolean,
      default: false
    },
    hideAdvancedSearch: {
      type: Boolean,
      default: false
    },
    isSearching: {
      type: Boolean,
      default: false
    },
    fieldOptions: {
      type: Array,
      default: () => []
    }
  },
  emits: ["input", "click:search", "click:export", "update:showAdvancedSearch"],
  setup(props, { emit }) {
    // MISC
    const store = useStore();

    // COMPUTED
    const search = computed(() => store.getters["queries/search"]);

    // METHODS
    const onInput = e => {
      const value = e.target.value;
      store.dispatch("queries/setSearch", value);
      emit("input", value);
    };

    return {
      search,
      onInput
    };
  }
};
</script>

<style scoped lang="scss">
$btn-color: #849db5;

.chk-search,
.chk-filters {
  @apply text-white;
  background-color: $btn-color;
}
.chk-filters-active {
  @apply bg-white;
  color: $btn-color;
  border-color: $btn-color;
}
</style>
