import VErrors from "@/components/inputs/VErrors";
import VHint from "@/components/VHint";

export default {
  components: {
    VErrors,
    VHint
  },
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: [String, Number, Boolean, Array],
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    inputClass: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    inputGroup: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      id: ""
    };
  },
  computed: {
    hasErrors() {
      return this.errors && Object.keys(this.errors).length > 0;
    }
  },
  mounted() {
    this.id = this.$_.uniqueId();
  }
};
