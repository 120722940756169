<template>
  <div
    v-if="showHints"
    class="dropdown mr-auto sm:mr-6"
    :class="{ 'intro-x': hasAnimation }"
  >
    <div
      class="notification dropdown-toggle"
      role="button"
      aria-expanded="false"
    >
      <i class="w-5 h-5 text-theme-13 fal fa-info-circle" />
    </div>

    <div class="w-96 pt-2 dropdown-menu">
      <div class="p-5 dropdown-menu__content box dark:bg-dark-6">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "@/store";
// Composables
import useAnimation from "@/composables/useAnimation";

export default {
  setup() {
    // Misc
    const store = useStore();

    // Composables
    const { hasAnimation } = useAnimation();

    // Computed
    const showHints = computed(() => store.getters["app/showHints"]);

    return {
      showHints,
      // useAnimation
      hasAnimation
    };
  }
};
</script>
