<template>
  <div>
    <div class="flex justify-between">
      <div>
        <slot name="label">
          {{ label }}
        </slot>
      </div>

      <div v-if="!hidePercentage">{{ percentage }}%</div>
    </div>

    <div class="progress mt-1">
      <div
        class="progress-bar bg-theme-1"
        :style="`width: ${percentage}%`"
        role="progressbar"
        aria-valuenow="0"
        aria-valuemin="0"
        aria-valuemax="100"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ""
    },
    hidePercentage: {
      type: Boolean,
      default: false
    },
    percentage: {
      type: [String, Number],
      default: 0,
      validator: value => parseInt(value) >= 0
    }
  }
};
</script>
