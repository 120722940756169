<template>
  <div>
    <slot :id="id" name="label">
      <div class="flex items-baseline">
        <label v-if="label" :for="id" class="form-label">
          {{ label }}
        </label>

        <VHint v-if="$slots.hint" class="ml-2">
          <slot name="hint" />
        </VHint>
      </div>
    </slot>

    <div class="input-group">
      <div v-if="inputGroup" class="input-group-text">
        {{ inputGroup }}
      </div>

      <input
        :id="id"
        :value="modelValue"
        :type="type"
        :disabled="disabled"
        :readonly="readonly"
        :placeholder="placeholder"
        autocomplete="off"
        :min="min"
        :class="[inputClass, { 'border-theme-6': hasErrors }]"
        class="form-control w-full"
        @input="$emit('update:modelValue', $event.target.value)"
      />
    </div>

    <VErrors :errors="errors" />
  </div>
</template>

<script>
import InputMixin from "@/mixins/InputMixin";

export default {
  mixins: [InputMixin],
  props: {
    type: {
      type: String,
      default: "text",
      validator: value => ["text", "password", "number"].includes(value)
    },
    min: {
      type: Number,
      default: 0
    }
  }
};
</script>
