<template>
  <div>
    <VAction
      :label="displayedLabel"
      :disabled="disabled"
      icon="delete"
      color="text-theme-6"
      @click="onClickConfirmDelete"
    />

    <DeleteModal
      v-if="isModalVisible"
      :id="id"
      :message="$t('app.delete_item', { name: deletable.name })"
      :is-deleting="isDeleting"
      @click:cancel="onClickCancel"
      @click:delete="onClickDelete"
    />
  </div>
</template>

<script>
import { inject, ref, computed } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useModal from "@/composables/useModal";
// Components
import VAction from "@/components/tables/VAction";
import DeleteModal from "@/components/modals/DeleteModal";

export default {
  components: {
    VAction,
    DeleteModal
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    textValue: {
      type: String,
      default: ""
    },
    deleteFunction: {
      type: Function,
      required: true
    },
    label: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();

    // Inject
    const _ = inject("lodash");

    // Data
    const id = ref(_.uniqueId());
    const deletable = ref({});
    const isDeleting = ref(false);

    // Computed
    const displayedLabel = computed(() => props.label || t("app.delete"));

    // Composables
    const { isModalVisible, showModal, hideModal } = useModal(`#${id.value}`);

    // Methods
    const setDeletable = () => {
      deletable.value = { ...props.item, name: props.textValue };
    };

    const onClickConfirmDelete = () => {
      setDeletable();
      showModal();
    };

    const deleteItem = async () => {
      isDeleting.value = true;
      await props.deleteFunction(deletable.value);
      isDeleting.value = false;
    };

    const onClickDelete = async () => {
      await deleteItem();
      hideModal();
    };

    return {
      displayedLabel,
      id,
      deletable,
      onClickConfirmDelete,
      onClickDelete,
      isDeleting,
      // useModal
      isModalVisible,
      onClickCancel: hideModal
    };
  }
};
</script>
