<template>
  <div>
    <slot :id="id" name="label">
      <div class="flex items-baseline">
        <label v-if="label" :for="id" class="form-label">
          {{ label }}
        </label>

        <VHint v-if="$slots.hint" class="ml-2">
          <slot name="hint" />
        </VHint>
      </div>
    </slot>

    <TomSelect
      :model-value="value"
      class="w-full chk-select"
      :multiple="multiple"
      :disabled="readonly"
      :options="{
        placeholder: placeholder,
        allowEmptyOption: allowEmptyOption,
        labelField: labelField,
        valueField: valueField,
        plugins: plugins,
        options: options,
        load: isFetched ? load : null
      }"
      :class="[inputClass, { 'has-errors': hasErrors }]"
      @update:modelValue="$emit('update:modelValue', $event)"
    />

    <VErrors :errors="errors" />
  </div>
</template>

<script>
import InputMixin from "@/mixins/InputMixin";

export default {
  mixins: [InputMixin],
  props: {
    options: {
      type: Array,
      default: () => []
    },
    multiple: {
      type: Boolean,
      default: false
    },
    allowEmptyOption: {
      type: Boolean,
      default: false
    },
    labelField: {
      type: String,
      default: "text"
    },
    valueField: {
      type: String,
      default: "value"
    },
    load: {
      type: Function,
      default: () => {}
    },
    isFetched: {
      type: Boolean,
      default: false
    },
    hideClearButton: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    value() {
      return Array.isArray(this.modelValue)
        ? this.modelValue
        : `${this.modelValue}`;
    },
    plugins() {
      if (this.hideClearButton || this.readonly || this.disabled) {
        return {};
      }

      return {
        clear_button: {}
      };
    }
  }
};
</script>

<style scoped lang="scss">
.has-errors ~ :deep(.tom-select .ts-input) {
  @apply border-theme-6;
}

.chk-select ~ :deep(.tom-select .ts-input.disabled) {
  opacity: 1;
  background-color: rgba(247, 250, 252, 1);
}

.chk-select ~ :deep(.tom-select .ts-input.disabled),
.chk-select ~ :deep(.tom-select .ts-input.disabled *) {
  cursor: not-allowed !important;
}
</style>
