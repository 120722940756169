<template>
  <div class="box" :class="{ 'intro-y': hasAnimation }">
    <div
      v-if="$slots.tabs"
      class="post overflow-hidden rounded-b-none shadow-none box"
    >
      <div
        class="post__tabs nav nav-tabs flex-col sm:flex-row justify-center lg:justify-start bg-gray-300 dark:bg-dark-2 text-gray-600"
      >
        <slot name="tabs" />
      </div>
    </div>

    <div
      v-if="$slots.title || $slots['title-actions']"
      class="px-5 py-3"
      :class="[
        titleClass,
        { 'border-b border-gray-200 dark:border-dark-5': !collapsed }
      ]"
      @click="$emit('click:title')"
    >
      <div class="flex justify-between items-center">
        <div class="flex justify-start items-center">
          <slot name="image" />

          <div>
            <h2 class="font-medium text-base">
              <slot name="title" />
            </h2>

            <slot name="subtitles" />
          </div>
        </div>

        <slot name="title-actions" />
      </div>
    </div>

    <div v-if="!collapsed">
      <div
        v-if="$slots.content"
        class="p-5"
        :class="{
          'border-b border-gray-200 dark:border-dark-5':
            $slots.actions || $slots['more-content']
        }"
      >
        <slot name="content" />
      </div>

      <div
        v-if="$slots['more-content']"
        class="p-5"
        :class="{
          'border-b border-gray-200 dark:border-dark-5': $slots.actions
        }"
      >
        <slot name="more-content" />
      </div>

      <div v-if="$slots.actions" :class="actionsClass" class="rounded-b-md p-3">
        <slot name="actions" />
      </div>
    </div>
  </div>
</template>

<script>
// Composables
import useAnimation from "@/composables/useAnimation";

export default {
  props: {
    collapsed: {
      type: Boolean,
      default: false
    },
    titleClass: {
      type: String,
      default: ""
    },
    actionsClass: {
      type: String,
      default: ""
    }
  },
  setup() {
    // Composables
    const { hasAnimation } = useAnimation();

    return {
      hasAnimation
    };
  }
};
</script>
