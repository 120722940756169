<template>
  <button
    class="btn uppercase tracking-widest whitespace-nowrap"
    :class="{ 'btn-secondary-soft cursor-not-allowed': disabled }"
    type="button"
    :disabled="disabled"
    @click="onClick"
  >
    <i v-if="icon" class="fal" :class="[icon, { 'mr-2': label }]" />

    <slot>
      {{ label }}
    </slot>

    <LoadingIcon
      v-if="isLoading"
      icon="oval"
      color="white"
      class="w-4 h-4 ml-2"
    />
  </button>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ["click"],
  setup(props, context) {
    const onClick = () => {
      if (props.disabled || props.isLoading) {
        return;
      }

      context.emit("click");
    };

    return {
      onClick
    };
  }
};
</script>
