<template>
  <VModal
    @click:cancel="$emit('click:cancel')"
    @click:confirm="$emit('click:confirm')"
  >
    <template #content>
      <div class="text-center">
        <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />

        <div class="text-3xl mt-5">
          {{ $t("app.are_you_sure") }}
        </div>

        <div class="text-gray-600 mt-2">
          <!-- eslint-disable-next-line -->
          <span v-html="message" />
        </div>
      </div>
    </template>

    <template #actions>
      <div class="flex justify-center px-5 pb-8">
        <VButton
          class="btn-outline-secondary w-24 mr-1"
          :label="$t('app.cancel')"
          @click="$emit('click:cancel')"
        />

        <VButton
          class="btn-danger"
          :is-loading="isDeleting"
          :label="$t('app.delete')"
          @click="$emit('click:delete')"
        />
      </div>
    </template>
  </VModal>
</template>

<script>
// Components
import VModal from "@/components/modals/VModal";
import VButton from "@/components/VButton";

export default {
  components: {
    VModal,
    VButton
  },
  props: {
    isDeleting: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ""
    }
  }
};
</script>
