<template>
  <Tippy v-if="label" tag="a" class="tooltip" :content="label">
    <button :disabled="disabled" class="w-10" @click="onClick">
      <LoadingIcon v-if="isLoading" icon="tail-spin" class="w-8 h-8" />
      <i
        v-else
        class="fa-lg"
        :class="`${storeIcon} ${iconColor} cursor-${displayedCursor}`"
      />
    </button>
  </Tippy>

  <button v-else :disabled="disabled" class="w-10" @click="onClick">
    <LoadingIcon v-if="isLoading" icon="tail-spin" class="w-8 h-8" />

    <i
      v-else
      class="fa-lg"
      :class="`${storeIcon} ${iconColor} cursor-${displayedCursor}`"
    />
  </button>
</template>

<script>
import { computed } from "vue";
import { useStore } from "@/store";

export default {
  props: {
    icon: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: ""
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    cursor: {
      type: String,
      default: "pointer"
    }
  },
  emits: ["click"],
  setup(props, context) {
    // MISC
    const store = useStore();

    // COMPUTED
    const storeIcon = computed(() =>
      store.getters["icons/getIcon"](props.icon)
    );
    const iconColor = computed(() =>
      props.disabled ? "text-theme-7 text-opacity-20" : props.color
    );
    const displayedCursor = computed(() =>
      props.disabled ? "not-allowed" : props.cursor
    );

    // METHODS
    const onClick = () => {
      if (props.disabled) {
        return;
      }

      context.emit("click");
    };

    return {
      displayedCursor,
      storeIcon,
      iconColor,
      onClick
    };
  }
};
</script>
