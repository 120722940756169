<template>
  <div class="w-full h-full">
    <div class="flex flex-row items-center justify-between">
      <VTitle :title="$t('app.integrations')" class="route-title" />

      <SearchField
        v-if="!isLoading && integrationItems?.length > 0"
        :hide-advanced-search="true"
        @input="onUpdateSearch"
      />
    </div>

    <div v-if="linkToken" class="transparent h-1">
      <MergeLink
        :key="linkToken"
        :link-token="linkToken"
        v-bind="{ onSuccess, onReady }"
      >
        <template #button="props">
          <button id="merge-link-button" @click="props.onClick" />
        </template>
      </MergeLink>
    </div>

    <div v-if="isLoading" class="flex justify-center items-center w-full h-2/4">
      <LoadingIcon icon="three-dots" class="w-8 h-8" />
    </div>

    <div v-else>
      <div
        v-if="integrationItems?.length > 0"
        class="grid grid-cols-5 justify-center items-center gap-4 justify-center"
      >
        <VCard
          v-for="item in integrationItems"
          :key="item?.name"
          class="border p-5 rounded-md border-gray-200 min-h-[180px] text-center cursor-pointer flex flex-col items-center"
          @click="onClickItem(item)"
        >
          <template #content>
            <img
              :src="item?.square_image"
              :alt="item?.name"
              class="w-28 h-28"
            />

            <div class="text-xs truncate pt-2">
              {{ item?.name }}
            </div>
          </template>
        </VCard>
      </div>

      <VCard v-else>
        <template #content>
          <div
            class="border p-5 mb-5 rounded-md border-gray-200 min-h-[10rem] cursor-pointer w-full text-s font-medium"
          >
            <div class="flex items-center gap-6 py-2">
              <div class="w-1/6 text-gray-600">
                {{ $t("app.product_name") }}
              </div>

              <div class="w-3/4">
                {{ data?.name.charAt(0).toUpperCase() + data?.name.slice(1) }}
              </div>
            </div>

            <div class="flex items-center gap-6 py-2">
              <div class="w-1/6 text-gray-600">
                {{ $t("app.category") }}
              </div>

              <div class="w-3/4">
                {{ data?.category?.toUpperCase() }}
              </div>
            </div>

            <div class="flex items-center gap-6 py-2">
              <div class="w-1/6 text-gray-600">
                {{ $t("app.statuses") }}
              </div>

              <div class="w-3/4">
                {{ data?.status }}
              </div>
            </div>

            <div class="flex items-center gap-6 py-2">
              <div class="w-1/6 text-gray-600">
                {{ $t("app.last_imported_at") }}
              </div>

              <div class="w-3/4">
                <span v-if="data?.last_imported_at">
                  {{ formatDate({ date: data?.last_imported_at }) }}
                </span>

                <span v-else>
                  {{ $t("app.never") }}
                </span>
              </div>
            </div>
          </div>

          <div
            v-if="!isLoading && integrationItems?.status === 'Linked'"
            class="flex items-center justify-end"
          >
            <VDeleteAction
              class="text-red-400"
              :item="data"
              :text-value="data?.name"
              :delete-function="onClickReset"
            />

            <VButton
              class="ml-4 btn-primary"
              :is-loading="isSynchronizing"
              @click="onSynchronize"
            >
              {{ $t("app.synchronize_now") }}
            </VButton>
          </div>

          <div
            class="grid grid-cols-2 gap-5 py-5 rounded-md min-h-[10rem] cursor-pointer"
          >
            <VSelect
              v-model="statusValue"
              :placeholder="$t('app.select')"
              class="mb-4"
              :label="$t('app.statuses')"
              :options="statusOptions"
            />

            <VSelect
              v-model="importFrequency"
              :placeholder="$t('app.select')"
              class="mb-4"
              :label="$t('app.import_frequency')"
              :options="frequencyOptions"
            />

            <VSelect
              v-model="departmentGroupType"
              :placeholder="$t('app.select')"
              class="mb-4"
              :label="$t('app.departments')"
              :options="departmentOptions"
            />

            <VSelect
              v-model="newUserStatus"
              :label="$t('app.new_user_status')"
              :placeholder="$t('app.select')"
              class="mb-4"
              :options="userStatusOptions"
            />

            <IntegrationsLicenseForm
              :integration-license-id="newUserLicense"
              @update:license="onUpdateLicense($event)"
            />

            <VText
              v-model="updateStatusEmailsValue"
              :label="$t('app.update_status_emails')"
              class="mb-4"
            >
              <template #hint>
                {{ $t("app.update_status_emails_subtext") }}
              </template>
            </VText>
          </div>

          <div class="flex justify-end items-end w-full">
            <VButton
              class="btn-primary"
              :is-loading="isUpdating"
              @click="onUpdateIntegration"
            >
              {{ $t("app.save") }}
            </VButton>
          </div>
        </template>
      </VCard>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed, inject } from "vue";
import { useI18n } from "vue-i18n";
import MergeLink from "@mergeapi/vue-merge-link";
// Composables
import useIntegration from "@/composables/useIntegration";
import useDisplay from "@/composables/useDisplay";
// Components
import VTitle from "@/components/VTitle";
import VCard from "@/components/VCard";
import VButton from "@/components/VButton";
import VDeleteAction from "@/components/VDeleteAction";
import VSelect from "@/components/inputs/VSelect";
import VText from "@/components/inputs/VText";
import SearchField from "@/components/SearchField";
import IntegrationsLicenseForm from "@/views/administration/integrations/IntegrationsLicenseForm";

export default {
  components: {
    VTitle,
    VCard,
    VButton,
    VDeleteAction,
    VSelect,
    VText,
    SearchField,
    MergeLink,
    IntegrationsLicenseForm
  },
  setup() {
    // Misc
    const { t } = useI18n();

    // Inject
    const _ = inject("lodash");

    // Constants
    const documentTitle = `${t("app.integrations")} - ${t(
      "app.administration"
    )}`;

    const CATEGORY = ["hris"];

    // Data
    const data = ref([]);
    const linkToken = ref("");
    const isLoading = ref(true);
    const isSynchronizing = ref(false);
    const isUpdating = ref(false);
    const integrationId = ref(null);
    const integrationItems = ref([]);

    const statusValue = ref("");
    const importFrequency = ref("");
    const departmentGroupType = ref("");
    const newUserStatus = ref("");
    const newUserLicense = ref("");
    const updateStatusEmailsValue = ref("");

    // Computed
    const departmentOptions = computed(() =>
      [
        "TEAM",
        "DEPARTMENT",
        "COST_CENTER",
        "BUSINESS_UNIT",
        "GROUP"
      ].map(x => ({ text: x, value: x }))
    );

    const userStatusOptions = computed(() => [
      {
        text: t("app.active"),
        value: "Active"
      },
      {
        text: t("app.inactive"),
        value: "Inactive"
      },
      {
        text: t("app.deactivated"),
        value: "Deactivated"
      }
    ]);

    const frequencyOptions = computed(() => [
      {
        text: t("app.daily"),
        value: "Daily"
      },
      {
        text: t("app.hourly"),
        value: "Hourly"
      },
      {
        text: t("app.manual"),
        value: "Manual"
      }
    ]);

    const statusOptions = computed(() => [
      {
        text: t("app.pending"),
        value: "Pending"
      },
      {
        text: t("app.linked"),
        value: "Linked"
      }
    ]);

    // Composables
    const {
      getEnabledIntegrations,
      getIntegrations,
      addIntegration,
      updateIntegration,
      deleteIntegration,
      synchronizeIntegrationData
    } = useIntegration();

    const { formatDate } = useDisplay();

    // Methods
    const onUpdateSearch = input => {
      isLoading.value = true;

      integrationItems.value = data.value.filter(item =>
        item?.name.toLowerCase().includes(input.toLowerCase())
      );

      isLoading.value = false;
    };

    const getData = async () => {
      const response = await getIntegrations();

      data.value = response?.payload?.data[0];

      if (!data.value || data.value?.status === "Pending") {
        const response = await getEnabledIntegrations();

        data.value = response?.payload?.results.filter(elem =>
          elem.categories.some(r => CATEGORY.includes(r))
        );
      } else {
        statusValue.value = data.value?.status;
        importFrequency.value = data.value?.import_frequency;
        departmentGroupType.value = data.value?.department_group_type;
        newUserStatus.value = data.value?.new_user_status;
        newUserLicense.value = data.value?.new_user_license_id;
        updateStatusEmailsValue.value = data.value?.update_status_emails;
        integrationId.value = data.value?.id;
      }

      integrationItems.value = data.value;
    };

    const onClickItem = async item => {
      const payload = {
        end_user_origin_id: item?.slug + _.uniqueId(),
        end_user_organization_name: item?.slug,
        categories: CATEGORY,
        integration: item?.slug
      };

      const response = await addIntegration(payload);

      linkToken.value = response?.payload?.data?.temporary_token;
      integrationId.value = response?.payload?.data?.id;
    };

    const onClickReset = async () => {
      onReset();
    };

    const onReset = async () => {
      isLoading.value = true;

      await deleteIntegration(data?.value?.id);

      await getData();

      isLoading.value = false;
    };

    const onReady = () => {
      document.getElementById("merge-link-button").click();
    };

    const onSuccess = async token => {
      const payload = {
        public_token: token,
        status: "Linked"
      };

      await updateIntegration(payload, integrationId.value);

      await getData();
    };

    const onUpdateIntegration = async () => {
      isUpdating.value = true;

      const payload = {
        status: statusValue.value,
        import_frequency: importFrequency.value,
        department_group_type: departmentGroupType.value,
        new_user_status: newUserStatus.value,
        new_user_license_id: newUserLicense.value,
        update_status_emails: updateStatusEmailsValue.value
      };

      await updateIntegration(payload, integrationId.value);

      isUpdating.value = false;

      await getData();
    };

    const onSynchronize = async () => {
      isSynchronizing.value = true;

      await synchronizeIntegrationData();

      isSynchronizing.value = false;
      isLoading.value = true;

      await getData();

      isLoading.value = false;
    };

    const onUpdateLicense = licenseId => {
      newUserLicense.value = licenseId;
    };

    // Lifecycle Hooks
    onMounted(async () => {
      isLoading.value = true;

      await getData();

      isLoading.value = false;
    });

    return {
      documentTitle,
      data,
      linkToken,
      isLoading,
      isSynchronizing,
      isUpdating,
      onReady,
      onSuccess,
      onUpdateIntegration,
      onSynchronize,
      onClickItem,
      onClickReset,
      onUpdateSearch,
      onUpdateLicense,
      integrationItems,
      departmentOptions,
      userStatusOptions,
      frequencyOptions,
      statusOptions,
      statusValue,
      importFrequency,
      departmentGroupType,
      newUserStatus,
      newUserLicense,
      updateStatusEmailsValue,
      // useDisplay
      formatDate
    };
  }
};
</script>
