<template>
  <div>
    <VSelect
      :model-value="integrationLicenseId"
      :options="options"
      :label="$t('app.new_user_license')"
      @update:modelValue="onUpdate"
    />

    <div class="flex justify-between items-center my-2">
      <div class="flex">
        <div class="colon text-gray-600">
          {{ $t("app.start_date") }}
        </div>

        <div>
          {{ startDate }}
        </div>
      </div>

      <div class="flex">
        <div class="colon text-gray-600">
          {{ $t("app.end_date") }}
        </div>

        <div>
          {{ expiryDate }}
        </div>
      </div>
    </div>

    <ProgressBar :percentage="percentage">
      <template #label>
        <!-- eslint-disable-next-line -->
        <span v-html="
            $t('app.users_assigned', {
              number: integrationLicense?.assigned_active_users,
              total: integrationLicense?.number_of_users
            })
          "
        />
      </template>
    </ProgressBar>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useDisplay from "@/composables/useDisplay";
import useOptions from "@/composables/useOptions";
// Components
import VSelect from "@/components/inputs/VSelect";
import ProgressBar from "@/components/ProgressBar.vue";

export default {
  components: {
    VSelect,
    ProgressBar
  },
  props: {
    integrationLicenseId: {
      type: Number,
      required: true
    }
  },
  emits: ["update:license"],
  setup(props, { emit }) {
    // Misc
    const { t } = useI18n();

    // Composables
    const { getResources, formatOptions } = useOptions();
    const { formatDate } = useDisplay();

    // Data
    const licenses = ref([]);

    // Computed
    const options = computed(() => {
      if (!licenses.value || licenses.value.length === 0) {
        return [];
      }

      return licenses.value.map(license => ({
        ...license,
        disabled:
          license?.expiry_status == "Expired" ||
          license?.assigned_active_users >= license?.number_of_users
      }));
    });

    const integrationLicense = computed(() => {
      return licenses.value.find(elem => elem.id == props.integrationLicenseId);
    });

    const startDate = computed(() => {
      const value = integrationLicense.value?.start_date ?? "";
      return formatDate({ date: value });
    });

    const expiryDate = computed(() => {
      const value = integrationLicense.value?.expiry_date ?? "";
      return formatDate({ date: value, fallback: t("app.perpetual_license") });
    });

    const percentage = computed(() => {
      if (
        !integrationLicense.value?.assigned_active_users ||
        !integrationLicense.value?.number_of_users
      ) {
        return 0;
      }

      const result = (
        (integrationLicense.value?.assigned_active_users /
          integrationLicense.value?.number_of_users) *
        100
      ).toFixed(0);
      return result > 100 ? 100 : result;
    });

    // Methods
    const onUpdate = licenseId => {
      emit("update:license", licenseId);
    };

    // Lifecycle Hooks
    onMounted(async () => {
      const response = await getResources("administration.licenses");
      licenses.value = formatOptions(response?.payload?.data);
    });

    return {
      options,
      onUpdate,
      startDate,
      expiryDate,
      percentage,
      integrationLicense,
      // useDisplay
      formatDate
    };
  }
};
</script>
