import { computed } from "vue";
import { useStore } from "@/store";
// Composables
import useOptions from "@/composables/useOptions";

export default function useAnimation() {
  // Misc
  const store = useStore();

  // Composables
  const { ALL_OPTIONS } = useOptions();

  // Computed
  const animate_ui = computed(() => store.getters["user/animate_ui"]);
  const hasAnimation = computed(() => {
    return animate_ui.value === ALL_OPTIONS.YES.value;
  });

  return {
    hasAnimation
  };
}
